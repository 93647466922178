const colors = {
  bgDark: "#111111",
  bgLight: "#E5EFFF",
  colorPrimary: "#434343",
  colorLight: "rgb(67, 67, 67)",
  fontprimary: "'Oxygen', sans-serif",
  bgbrown: "#111111",
  bglightbrown: "#FAF5EA",
  bgBlue: "#443DFF",
  white: "#FFFFFF",
  boxShadow: "#3f55ff33",

  // new color names
  /* main bg */
  extremeBlack: "#111111",
  extremeWhite: "#E5EFFF",
  /* buttons and other main things color */
  primaryBlue: "#443dff",
  /* used in box shadow */
  secondaryBlue: "#433dff77",
  tertiaryBlue: "#726dff",
  lightGray: "#9595958b",
  textFieldBackground: "rgba(67, 61, 255, 0.4)",
  darkGray: "#2a2a2a",
  extremeBlue: "#1e2732",
};

export default colors;
