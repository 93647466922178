import { useEffect } from "react";

function Home() {
  useEffect(() => {
    window.location.href = "https://www.guidelinebuddy.com/";
  }, []);

  return null; // No UI needed since we are redirecting
}

export default Home;
